import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import HttpService from './http.service';

@Injectable({ providedIn: 'root' })
export class ChainService {
  public subject = new BehaviorSubject<string>('PMC');

  constructor(private http: HttpService) {
    this.getChainName();
  }
  private getChainName() {
    this.http.getChainNameAPI().subscribe((res) => {
      const { success } = res;
      if (success) {
        const chainName = res.data;
        this.subject.next(chainName);
      }
    });
  }
}
