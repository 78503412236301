import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [],
  imports: [
    NzDescriptionsModule,
    NzSpinModule,
    NzButtonModule,
    NzTableModule,
    NzMessageModule,
    NzBreadCrumbModule,
    NzPaginationModule,
    NzModalModule,
    NzRateModule,
    NzToolTipModule,
    NzInputModule,
    NzDropDownModule,
    NzIconModule,
  ],
  exports: [
    NzDescriptionsModule,
    NzSpinModule,
    NzButtonModule,
    NzTableModule,
    NzMessageModule,
    NzBreadCrumbModule,
    NzPaginationModule,
    NzModalModule,
    NzRateModule,
    NzToolTipModule,
    NzInputModule,
    NzDropDownModule,
    NzIconModule,
  ],
})
export class ZorroModule {}
