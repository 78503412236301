import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import {
  Assets,
  Block,
  BlockDetail,
  Data,
  Options,
  Params,
  Result,
  Transaction,
} from 'src/domain';

import { TRANSACTIONS_REQ } from 'src/domain/request';
import {
  AccountAssets,
  AddressDetail,
  DPAddress,
  DPAlbum,
  DPIssue,
  Entity,
  EventDetail,
  Search,
} from 'src/domain/response';

@Injectable({ providedIn: 'root' })
export default class HttpService {
  private readonly baseUrl = window.envConfig.apiUrl + '/browser';

  constructor(private http: HttpClient) {}

  /** 获取链的名称 */
  getChainNameAPI() {
    const url = '/public/mainAssetType';
    return this.getRequest<string>(url, {});
  }

  getBlocksAPI(params: Params) {
    const url = '/public/blocks';
    return this.getRequest<Data<Block[]>>(url, { params });
  }
  getEventsAPI(params: TRANSACTIONS_REQ) {
    const url = '/public/transactions';
    return this.getRequest<Data<Transaction[]>>(url, { params });
  }
  /** 获取地址信息 */
  getAddressAPI(params: { address: string }) {
    const url = `/public/address`;
    return this.getRequest<AddressDetail>(url, { params });
  }
  /** 获取接口详情接口 */
  getEventDetailAPI(params: { signature: string }) {
    const url = `/public/transaction`;
    return this.getRequest<EventDetail>(url, { params });
  }
  /** 获取区块详情接口 */
  getBlockDetailAPI(height: number) {
    const url = `/public/block/${height}`;
    return this.getRequest<BlockDetail>(url);
  }
  /** 获取事件详情列表接口 */
  getGenesisBlockAPI() {
    const url = '/public/genesisBlock';
    return this.getRequest<Data<EventDetail[]>>(url, {});
  }
  /** 获取更多DP发行记录（DP发行记录查询更多时使用，默认15个） */
  getDPIssueAPI(params: { address: string; page: number; pageSize: number }) {
    const url = '/public/dpIssue';
    return this.getRequest<Data<DPIssue[]>>(url, { params });
  }
  getDPListAPI(params: TRANSACTIONS_REQ) {
    const url = '/public/issueTransaction';
    return this.getRequest<Data<Transaction[]>>(url, { params });
  }
  /** 获取拥有的同质化资产列表 */
  getAccountAssetsAPI(params: {
    address: string;
    page: number;
    pageSize: number;
  }) {
    const url = '/public/accountAssets';
    return this.getRequest<Data<AccountAssets[]>>(url, { params });
  }
  /** 获取更多DP（现拥有DP查询更多时使用，默认15个） */
  getDpAddressAPI(params: { address: string; page: number; pageSize: number }) {
    const url = '/public/dpAddress';
    return this.getRequest<Data<DPAddress[]>>(url, { params });
  }
  /** 获取DP专辑详情 */
  getDpAlubmDetailAPI(signature: string) {
    const url = '/public/dPEntityFactory';
    const params = {
      signature,
    };

    return this.getRequest<DPAlbum>(url, { params });
  }
  //查看DP详情(旧接口)
  getDPTransactionDetailAPI(signature: string, entityId: string) {
    const url = '/public/dPDetails';
    const params = {
      entityId,
      signature,
    };
    return this.getRequest<Entity>(url, { params });
  }

  getAssetsListAPI(params: Params) {
    const url = '/assets';
    return this.getRequest<Data<Assets[]>>(url, { params });
  }

  /** 获取交易体接口 */
  getTransactionBodyDetailAPI(signature: string) {
    const url = '/public/transactionBodyDetail';
    const params = {
      signature,
    };
    return this.getRequest<Data<any>>(url, { params });
  }

  /** input search接口 */
  handleSearchAPI(keyword: string, versionType: number = 1) {
    const url = '/public/search';
    const params = {
      keyword,
      versionType,
    };
    return this.getRequest<Search>(url, { params });
  }
  /** 获取账户资产类型的总量 */
  getAssetTotalAmountAPI(magic: string, assetType: string) {
    const url = '/public/assetTotalAmount';
    const params = {
      magic,
      assetType,
    };
    return this.getRequest<string>(url, { params });
  }
  getAccountsAssetAPI(params: {
    sourceChainMagic: string;
    assetType: string;
    address?: string;
    page?: number;
    pageSize?: number;
  }) {
    const url = '/asset/accounts';
    return this.getRequest<Data<{ a: string; n: string }[]>>(url, { params });
  }
  private handleError<T>(httpError: HttpErrorResponse) {
    const { status, error } = httpError;

    const result = {
      success: <false>false,
      error: <Error>error.error,
    } as unknown as Result<T>;
    return of(result);
  }

  private getRequest<T>(url: string, options: Options = {}) {
    return this.http
      .get<Result<T>>(this.baseUrl + url, options)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError<T>(error))
      );
  }

  private postRequest<T>(url: string, body: any, options: Options = {}) {
    return this.http
      .post<Result<T>>(this.baseUrl + url, body, options)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError<T>(error))
      );
  }
}
