<div class="flex justify-center pt-10">
  <div class="flex flex-col justify-center mr-10">
    <div class="text-2xl text-black font-bold">
      {{ "OOP_THE_PAGE_YOU_ARE_TRYING_TO_ACCESS_DOES_NOT_EXIST" | translate }}
    </div>
    <div class="text-title-100 text-sm mt-5">
      {{
        "PLEASE_REFRESH_THE_PAGE_OR_VERITY_YOUR_NETWORK_CONNECTIVITY"
          | translate
      }}
    </div>
    <div>
      <button
        class="text-sm my-5 bg-white border h-10 text-black px-4 rounded"
        (click)="backHome()"
      >
        {{ "BackToHome" | translate }}
      </button>
    </div>
  </div>

  <img src="./assets/images/Error page_illustrations.png" alt="未找到路径" />
</div>
