import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import goodStorage from 'good-storage';
import { ChainService } from 'src/service/chain.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private chain: ChainService
  ) {
    const language = goodStorage.get('language') || 'eng';
    this.translate.use(language);
  }
}
